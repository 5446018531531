import React, { Component } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroller';
import AppConfig from '../AppConfig'

class Notes extends Component {
	constructor(props) {
		super(props)
		this.state = {
			notes: [],
			hasMore: true,
			under: null
		}

		this.getNotes = this.getNotes.bind(this)
		this.postNote = this.postNote.bind(this)
	}
	componentDidMount() {
		document.getElementById("noteArea").focus()
	}
	componentWillMount() {
		this.getNotes()
	}
	getNotes() {
		var self = this;
		self.setState({
			hasMore: false
		})
		axios.get(AppConfig.apiUrl+'/paging.php?under='+this.state.under)
		.then((response) => {
			if (response.data.length > 0) {
				var last = response.data[response.data.length - 1].id;
				this.setState({
					under: last
				})
				var willReturn = self.state.notes;
				response.data.forEach((res, key) => {
					willReturn.push(res)
				})
				this.setState({
					notes: willReturn
				})
			}
			if (response.data.length < 1) {
				this.setState({
					hasMore: false
				})
			} else {
				this.setState({
					hasMore: true
				})
			}
		})
		.catch((error) => {
			this.setState({
				hasMore: true
			})
			console.log(error)
		})
	}
	postNote() {
		const data = "note="+document.getElementById('noteArea').value
		axios.post(AppConfig.apiUrl+'/post.php', data)
		.then((response) => {
			this.getNotes()
			document.getElementById('noteArea').value = ''
		})
		.catch((error) => {
			console.log(error.response)
		})
	}
	render() {
		const loader = {
			position: "fixed",
			zIndex: 99,
			top: "0px",
			left: "0px",
			background: "#0000003b",
			width: "100%",
			height: "100%",
			textAlign: "center",
			lineHeight: "100vh",
			color: "white",
			fontSize: "25px"
		};

		var items = [];
		this.state.notes.map((res, i) => {
			items.push(
				<div key={res.id}>
					<hr/>
					<small className="text-muted">Posted at <span className="text-info">{res.date}</span> on <span className="text-info">{res.time}</span></small><br/>
					{res.note}
				</div>
			);
		});
		return (
			<div className="container mb-5">
				<div className="text-center p-5">
					<h1 className="text-white">
						Simple Notes<br/>
						<small>Lets build some awesome app!</small>
					</h1>
				</div>
				<div className="row">
					<div className="col-12 col-md-8 offset-md-2 mb-2">
						<div className="card">
							<div className="card-body">
								<div className="form-group">
									<button className="float-right btn btn-sm btn-primary" onClick={() => this.postNote()}>Post</button>
									<label htmlFor="noteArea">New note</label>
									<textarea className="form-control" id="noteArea" rows="3" placeholder="Type here.."></textarea>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-8 offset-md-2">
						<div className="card">
							<div className="card-body">
								<div key="counter" className="text-right">
									<span className="text-primary">{this.state.notes.length}</span> note
								</div>
								<InfiniteScroll
									pageStart={0}
									loadMore={this.getNotes}
									hasMore={this.state.hasMore}
									loader={<div className="text-center" key={0}>Loading ...</div>}
								>
								{items}
								</InfiniteScroll>
								<hr/>
								<p className="thin text-center">Created by <a href="https://www.syamsulanwar.com">Syamsul Anwar</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Notes;