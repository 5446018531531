import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Page from './Page'
class AppRouter extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<div>
					<Route exact path="/" component={Page.Notes}/>
					<Route exact path="/test" component={Page.Test}/>
				</div>
			</BrowserRouter>
		);
	}
}

export default AppRouter;